import React from "react";
import "./events.scss";
import left__flower from "../../photos/flowers/events__topLeft.svg";
import right__flower from "../../photos/flowers/events__topRight.svg";
import mid__leaves from "../../photos/flowers/events__midLeft.svg";
import EventCarousel from "../eventCarousel/eventCarousel";

export default function events() {
  return (
    <div>
      <div className="events" id="events">
        <div className="events__bigBox">
          <div>
            <img
              src={left__flower}
              alt="decorative flower"
              className="events__flower--topLeft"
            ></img>
            <img
              src={mid__leaves}
              alt="decorative leaves"
              className="events__flower--midLeft"
            ></img>
          </div>
          <div className="events__title--box">
            <h4 className="events__title">Upcoming Events</h4>
            <EventCarousel />
          </div>
          <img
            src={right__flower}
            alt="decorative flowers"
            className="events__flower--topRight"
          ></img>
        </div>
      </div>
    </div>
  );
}
