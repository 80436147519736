import React from "react";
import "./hero.scss";
import left__flower from "../../photos/flowers/hero_left.svg";
import center__flower from "../../photos/flowers/hero_center.svg";
import right__flower from "../../photos/flowers/hero_right.svg";
import right__flowerMobile from "../../photos/flowers/hero__right--mobile.svg";

export default function hero() {
  const scrollToContact = () => {
    const events = document.getElementById("contact");
    if (events) {
      events.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToEvents = () => {
    const events = document.getElementById("events");
    if (events) {
      events.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <div className="hero__bigBox" id="top">
        <img
          src={left__flower}
          alt="decorative flower"
          className="hero__leftFlower"
        ></img>

        <section className="hero__box">
          <h1 className="hero__title">A Flamenco Collective in New Orleans</h1>
          <p className="hero__paragraph">
            Jaleo Flamenco is a community of musicians and dancers dedicated to
            promoting the art of flamenco through performances, classes, and
            other events.
          </p>
          <div>
            <button className="hero__button--contact" onClick={scrollToContact}>
              Contact Us
            </button>
            <button
              className="hero__button--learnMore"
              onClick={scrollToEvents}
            >
              Learn More
            </button>
          </div>
          <img
            src={center__flower}
            alt="decorative leaves"
            className="hero__centerFlower"
          ></img>
          <img
            src={right__flowerMobile}
            alt="decorative rose"
            className="hero__rightFlower--mobile"
          ></img>
        </section>
        <img
          src={right__flower}
          alt="decorative rose"
          className="hero__rightFlower"
        ></img>
      </div>
    </div>
  );
}
