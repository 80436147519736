import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "./eventCarousel.scss";
// Import Swiper styles
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import event1 from "../../photos/events/Valencia final.png";
import event2 from "../../photos/events/Six-week flamenco series 2.png";
// import event3 from "../../photos/events/melcruz.PNG";
// import video3 from "../../photos/events/VID-20230921-WA0011.mp4";

export default function eventCarousel() {
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
      slidesPerView={1}
      breakpoints={{
        1511: {
          slidesPerView: 1,
        },
        1512: {
          slidesPerView: 2,
        },
      }}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
      }}
      // pagination={true}
      onSwiper={(swiper) => console.log(swiper)}
      //   onSlideChange={() => console.log("slide change")}
      speed={2000}
      loop={true}
      className="renderEvents__bigBox"
    >
      {/* <SwiperSlide>
        <div className="renderEvents__box">
          <video height="394px" width="350px" loop autoplay muted controls>
            <source src={video3} type="video/mp4" />
          </video>
          <div className="renderEvents__info">
            09/29 between 2pm @ Nola Jazz Museum
          </div>
        </div>
      </SwiperSlide> */}
      <SwiperSlide>
        <div className="renderEvents__box">
          <img
            src={event1}
            alt="flyer for jaleo flamenco"
            className="renderEvents__event1"
          ></img>
          <div className="renderEvents__info">
            5/7 <br />@ Valencia Restaurant
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="renderEvents__box">
          <img
            src={event2}
            alt="flyer for workshop"
            className="renderEvents__event2"
          ></img>
          <div className="renderEvents__info">
            5/11 - 6/8
            <br />@ Cafe Istanbul
          </div>
        </div>
      </SwiperSlide>
      {/* <SwiperSlide>
        <div className="renderEvents__box">
          <img
            src={event3}
            alt="flyer for workshop"
            className="renderEvents__event2"
          ></img>
          <div className="renderEvents__info">
            4/12 & 4/13 <br />@ Dance Quarter
          </div>
        </div>
      </SwiperSlide> */}
    </Swiper>
  );
}
